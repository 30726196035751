import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './OrderReport.css'

function OrderReport() {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [error, setError] = useState('') // Добавлено состояние для ошибок

  const minDate = new Date(2023, 5, 1)

  const handleGenerate = () => {
    const start = startDate.toISOString().slice(0, 10)
    const end = endDate.toISOString().slice(0, 10)
    fetch(
      `https://robern-reporting-tool.3kit.com/reports/generate?startDate=${start}&endDate=${end}&token=E8NJTeQbry2V7fmsatSq3KgUAuRn65DY9CxWMjPBwdhvZGLXpF`
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.error || 'Unknown error')
          })
        }
        return response.blob()
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `report-${start}-to-${end}.csv`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        setError('')
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setError(error.message)
      })
  }

  return (
    <div className="order-report">
      <h1>Order Reports</h1>
      {error && <div className="error-message">{error}</div>} {}
      <div className="datepicker-wrapper">
        <label>Start date: </label>
        <DatePicker
          className="DatePicker__input"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          minDate={minDate}
        />
      </div>
      <div className="datepicker-wrapper">
        <label>End date: </label>
        <DatePicker
          className="DatePicker__input"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          minDate={minDate}
        />
      </div>
      <button onClick={handleGenerate}>Generate</button>
    </div>
  )
}

export default OrderReport
