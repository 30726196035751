import React, { useState, useEffect } from 'react'
import './App.css'
import OrderReport from './OrderReport'

function App() {
  const [isAuthorized, setIsAuthorized] = useState(null)

  useEffect(() => {
    const checkToken = () => {
      const hardcodedToken = `E8NJTeQbry2V7fmsatSq3KgUAuRn65DY9CxWMjPBwdhvZGLXpF`
      const queryParams = new URLSearchParams(window.location.search)
      const token = queryParams.get('token')

      setIsAuthorized(token === hardcodedToken)
    }

    checkToken()
  }, [])

  if (isAuthorized === null) {
    return <div>Loading...</div>
  }

  return (
    <div className="App">
      {isAuthorized ? <OrderReport /> : <div>Access Denied: Unauthorized</div>}
    </div>
  )
}

export default App
